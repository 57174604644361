import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BudgetMember } from '@app/budget';
import { Observable } from 'rxjs';
import { AuthenticationService } from '@app/auth';

@Injectable({
  providedIn: 'root',
})
export class BudgetMemberService {
  private budgetId: number = 1;

  constructor(private http: HttpClient, private auth: AuthenticationService) {}

  public getBudgetMembers(): Observable<BudgetMember[]> {
    return this.http.get<BudgetMember[]>(`/budget/members`);
  }

  public createBudgetMember(dto: {}): Observable<BudgetMember> {
    return this.http.post<BudgetMember>(`/budget/members`, dto);
  }
  public addBudgetMember(personId: number, permissions: {}[]): Observable<BudgetMember> {
    return this.http.post<BudgetMember>(`/budget/members/${personId}`, permissions);
  }

  public updateBudgetMember(buildingId: number, dto: {}): Observable<BudgetMember> {
    return this.http.put<BudgetMember>(`/budget/members/${buildingId}`, dto);
  }
  public deleteBudgetMember(buildingId: number): Observable<HttpResponse<any>> {
    return this.http.delete<HttpResponse<any>>(`/budget/members/${buildingId}`, {
      observe: 'response',
    });
  }
}
