import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse } from '@app/@shared';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PasswordManagementService {
  private readonly apiUrl: string = environment.serverUrl;

  constructor(private http: HttpClient) {}

  public changePassword(oldPassword: string, newPassword: string): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(`${this.apiUrl}/api/auth/change-password`, {
      oldPassword: oldPassword,
      newPassword: newPassword,
    });
  }

  public requestPasswordReset(email: string): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(`${this.apiUrl}/api/auth/reset-password/request`, null, {
      params: { email: email },
    });
  }

  public passwordResetConfirmation(password: string, token: string): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(`${this.apiUrl}/api/auth/reset-password/confirm`, {
      password: password,
      resetToken: token,
    });
  }
}
