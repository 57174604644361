import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MaterialModule } from '@app/material.module';
import { LoaderComponent } from './component/loader/loader.component';
import { PrimeNgModule } from '@app/prime-ng.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormErrorPipe } from './pipe/form-error.pipe';

import { ColorIconComponent } from './component/color-icon/color-icon.component';
import { ColorTagComponent } from './component/color-tag/color-tag.component';
import { ConfirmationDialogComponent } from './component/confirmation-dialog/confirmation-dialog.component';
import { CurrencyI18nDirective } from './directive/currency-i18n.directive';
import { CurrencyFormInputComponent } from './component/currency-form-input/currency-form-input.component';
import { ApiResponseDialogComponent } from './component/api-response-dialog/api-response-dialog.component';
import { ApiErrorResponseDialogComponent } from './component/api-error-response-dialog/api-error-response-dialog.component';

@NgModule({
  imports: [MaterialModule, CommonModule, PrimeNgModule, ReactiveFormsModule, FormsModule],
  declarations: [
    LoaderComponent,

    FormErrorPipe,
    ColorIconComponent,
    ColorTagComponent,
    ConfirmationDialogComponent,
    CurrencyI18nDirective,
    CurrencyFormInputComponent,
    ApiResponseDialogComponent,
    ApiErrorResponseDialogComponent,
  ],
  providers: [FormErrorPipe],
  exports: [
    LoaderComponent,
    FormErrorPipe,
    ColorIconComponent,
    ColorTagComponent,
    CurrencyI18nDirective,
    CurrencyFormInputComponent,
  ],
})
export class SharedModule {}
