import { Budget } from '@app/budget/model';

export class Building {
  id!: number;
  budgetId!: number;
  budget!: Budget;

  name!: string;

  description: string | null = null;

  address: string | null = null;
  public constructor(b: Building, c?: Budget) {
    this.id = b.id;
    this.budgetId = b.budgetId;
    this.name = b.name;
    this.description = b.description;
    this.address = b.address;
    this.budget = c === undefined ? new Budget(b.budget) : c;
  }
}
