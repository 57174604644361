<!-- 
<div class="flex flex-row w-full h-full bg-wrapper" style="height: 100vh">
  

  <div class="p-8 flex flex-col justify-center  w-full bg-orange-50 ">
    <form
      (ngSubmit)="login()"
      [formGroup]="form"
      novalidate
      class="flex flex-col gap-4 mx-auto p-20 bg-white rounded-xl w-full"
    >
      <h2 class="text-center">Bem-vindo(a) de volta</h2>
      <mat-form-field [hideRequiredMarker]="true" appearance="outline" class="w-full">
        <mat-label>Usuário</mat-label>
        <input type="text" matInput formControlName="username" required />
        <mat-error>{{ form.controls.username | formError }}</mat-error>
      </mat-form-field>
      <app-password-input class="w-full" [formControl]="form.controls.password" />
      <a class="ml-auto text-blue-600 hover:text-blue-800" routerLink="/password-reset-request">Esqueceu sua senha?</a>

      <button mat-raised-button color="primary" type="submit" [disabled]="form.invalid || isLoading">
        <span>Entrar</span>
      </button>
      <hr />
      <p class="text-xl">
        Ainda não tem uma conta?
        <a class="ml-auto text-blue-600 hover:text-blue-800" routerLink="/register">Crie uma agora!</a>
      </p>
    </form>
  </div>
  <div class="h-full max-md:hidden lg:min-w-1/ lg:max-md:min-w-1/2">
    <img
      src="assets/trees.png"
      style="
        width: 100%;
        height: 100%;
        object-fit: cover;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50% 50%;
      "
      alt=""
    />
  </div> 
</div>
-->

<div class="bg-wrapper" style="min-height: 100vh; height: 100%">
  <div class="ml-auto flex flex-col justify-center h-full lg:w-1/2 xl:w-2/5 lg:bg-white">
    <div class="bg-white py-10 rounded-xl mx-2 sm:mx-auto max-w-xl lg:max-w-full">
      <div class="font-sans text-center">
        <h2 class=" ">Crie sua conta!</h2>
        <p>Cadastre-se e comece a controlar suas finanças de forma prática e protegida!</p>
      </div>

      <form (ngSubmit)="onSubmit()" [formGroup]="form" novalidate class="flex flex-col gap-4 mt-10 px-10 lg:px-20">
        <div class="w-full flex flex-col md:flex-row md:gap-5">
          <mat-form-field [hideRequiredMarker]="true" appearance="outline" class="w-full">
            <mat-label>Nome</mat-label>
            <input type="text" matInput [formControl]="form.controls.givenName" required />
            <mat-error>{{ form.controls.givenName | formError }}</mat-error>
          </mat-form-field>
          <mat-form-field [hideRequiredMarker]="true" appearance="outline" class="w-full">
            <mat-label>Sobrenome</mat-label>
            <input type="text" matInput [formControl]="form.controls.familyName" required />
            <mat-error>{{ form.controls.familyName | formError }}</mat-error>
          </mat-form-field>
        </div>
        <mat-form-field [hideRequiredMarker]="true" appearance="outline" class="w-full">
          <mat-label>Usuário</mat-label>
          <input type="text" matInput [formControl]="form.controls.username" required />
          <mat-error>{{ form.controls.username | formError }}</mat-error>
        </mat-form-field>
        <mat-form-field [hideRequiredMarker]="true" appearance="outline" class="w-full">
          <mat-label>E-mail</mat-label>
          <input type="email" matInput [formControl]="form.controls.email" required />
          <mat-error>{{ form.controls.email | formError }}</mat-error>
        </mat-form-field>

        <app-password-input class="w-full" [formControl]="form.controls.password" />

        <!-- <div class="w-full"> -->

        <!-- <span class="">Não se preocupe, nós ajudamos você a recuperá-la.</span> -->
        <!-- </div> -->

        <button mat-raised-button color="primary" type="submit" [disabled]="form.invalid || isLoading">
          <span>Crie sua conta</span>
        </button>
        <hr />
        <p class="text-xl text-center">
          Já tem uma conta?
          <a class="ml-auto text-blue-600 hover:text-blue-800" routerLink="/register">Clique aqui para entrar</a>
        </p>
      </form>
    </div>
  </div>
  <!-- <div class="h-full max-md:hidden lg:min-w-1/ lg:max-md:min-w-1/2">
    <img
      src="assets/trees.png"
      style="
        width: 100%;
        height: 100%;
        object-fit: cover;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50% 50%;
      "
      alt=""
    />
  </div> -->
</div>
<!-- <div class="fx-flex-fill fx-layout-column fx-align-center-center mat-typography">
  <div>
    <h1 class="fx-align-center-center">Cadastrar usuário</h1>
    <div class="fx-layout-row fx-align-center-center" style="gap: 2rem"></div>
  </div>
  <div class="fx-align-center-center">
    <mat-card class="login-box" style="width: 100%; max-width: 400px; padding: 2rem; border-radius: 8px">
      <form (ngSubmit)="onSubmit()" [formGroup]="form" novalidate>
        <div class="fx-layout-column login-fields">
          <mat-form-field [hideRequiredMarker]="true" appearance="outline">
            <mat-label>Nome</mat-label>
            <input type="text" matInput formControlName="givenName" placeholder="Ex: João" required />
            <mat-error *ngIf="form.controls.givenName.invalid">{{ form.controls.givenName | formError }}</mat-error>
          </mat-form-field>
          <mat-form-field [hideRequiredMarker]="true" appearance="outline">
            <mat-label>Sobrenome</mat-label>
            <input type="text" matInput formControlName="familyName" placeholder="Ex: Silva" required />
            <mat-error *ngIf="form.controls.familyName.invalid">{{ form.controls.familyName | formError }}</mat-error>
          </mat-form-field>
          <mat-form-field [hideRequiredMarker]="true" appearance="outline">
            <mat-label>Usuário</mat-label>
            <input type="text" matInput formControlName="username" placeholder="Ex: joaosilva" required />
            <mat-error *ngIf="form.controls.username.invalid">{{ form.controls.username | formError }}</mat-error>
          </mat-form-field>
          <mat-form-field [hideRequiredMarker]="true" appearance="outline">
            <mat-label>Email</mat-label>
            <input type="text" matInput formControlName="email" placeholder="Ex: joao@exemplo.com" required />
            <mat-error *ngIf="form.controls.email.invalid">{{ form.controls.email | formError }}</mat-error>
          </mat-form-field>
          <mat-form-field [hideRequiredMarker]="true" appearance="outline">
            <mat-label>Senha</mat-label>
            <input type="password" matInput formControlName="password" placeholder="Mínimo 4 caracteres" required />
            <mat-error *ngIf="form.controls.password.invalid">{{ form.controls.password | formError }}</mat-error>
          </mat-form-field>
          <br />
          <button mat-raised-button color="primary" type="submit" [disabled]="form.invalid" style="width: 100%">
            <span>Criar</span>
          </button>
        </div>
      </form>
    </mat-card>
  </div>
</div> -->
