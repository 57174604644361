import { Building } from '@app/building';
import { BudgetMember } from './budget-member';
import { Person } from '../../auth/model/person';

export class Budget {
  id!: number;
  ownerId!: number;
  owner!: Person;
  name!: string;
  currentBalance!: number;
  members: BudgetMember[] = [];
  buildings: Building[] = [];

  constructor(c: Budget) {
    this.id = c.id;
    this.ownerId = c.ownerId;
    this.owner = new Person(c.owner);
    this.name = c.name;
    this.currentBalance = c.currentBalance;
    this.members = c.members.map((m) => new BudgetMember(m));
    this.buildings = c.buildings.map((b) => new Building(b, c));
  }
}
