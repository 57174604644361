import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject, filter, map, of, switchMap, tap } from 'rxjs';

import { CredentialsService } from './credentials.service';
import { User } from '../model';
import { HttpClient } from '@angular/common/http';

import { Router } from '@angular/router';
import { Logger } from '@app/@shared';
import { Budget } from '@app/budget';
import { CreateUserDto } from '../user-management/register/register.component';

export interface LoginContext {
  username: string;
  password: string;
  remember?: boolean;
}
const log = new Logger('AuthenticationService');

/**
 * Provides a base for authentication workflow.
 * The login/logout methods should be replaced with proper implementation.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  public _currentUser: ReplaySubject<User> = new ReplaySubject<User>();
  private _user!: User;
  private _currentBudget: Budget | null = null;
  private _budgets: Budget[] = [];

  constructor(private credentialsService: CredentialsService, private router: Router, private http: HttpClient) {}

  /**
   * Authenticates the user.
   * @param context The login parameters.
   * @return The user credentials.
   */
  login(context: LoginContext): Observable<any> {
    // Replace by proper authentication call
    const data = undefined;
    this.credentialsService.setCredentials(data, context.remember);
    return this.http
      .post(`/api/login`, { username: context.username, password: context.password }, { observe: 'response' })
      .pipe(
        tap((response: any) => {
          this.credentialsService.setCredentials({
            username: context.username,
            token: response.headers.get('X-Auth-Token')!,
          });
        }),
        switchMap(() => this.userInfo())
      );
  }

  register(dto: CreateUserDto): Observable<User> {
    return this.http.post<User>(`/api/users/register`, dto);
  }

  /**
   * Logs out the user and clear credentials.
   * @return True if the user was logged out successfully.
   */
  logout(): Observable<boolean> {
    // Customize credentials invalidation here
    this.credentialsService.setCredentials();
    this.router.navigate(['/login']);
    return of(true);
  }

  public userInfo(): Observable<User> {
    log.info('requesting user info');
    return this.http.get<User>(`/api/users/info`).pipe(
      map((response) => {
        const u = new User(response);
        log.debug(`received user ${u}`);
        this._user = u;
        this._budgets = u.budgets;
        if (!!u.budgets && u.budgets.length > 0) this._currentBudget = u.budgets[0];
        this._currentUser.next(u);
        return u;
      }),
      tap((u) => {
        console.log(u);
      })
    );
  }
  public changeBudget(budget: Budget): void {
    this._currentBudget = budget;
  }
  get currentUser(): Observable<User> {
    return this._currentUser.asObservable();
  }

  get accessToken(): string | null {
    if (this.credentialsService.credentials) {
      return this.credentialsService.credentials.token;
    }
    return null;
  }

  get budgets(): Budget[] {
    return this.budgets;
  }

  get currentBudget(): Budget | null {
    return this._currentBudget;
  }

  get user(): User {
    return this._user;
  }
}
