import { Component, OnInit, OnDestroy } from '@angular/core';

import { EMPTY, Subscription, merge } from 'rxjs';

import { environment } from '@env/environment';
import { Logger, UntilDestroy, untilDestroyed } from '@shared';
import { MatDialog } from '@angular/material/dialog';
import { CreateBudgetDialogComponent } from './budget/create-budget-dialog/create-budget-dialog.component';
import { AuthenticationService } from './auth';

const log = new Logger('App');

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private sub$!: Subscription;
  constructor(private auth: AuthenticationService, private dialog: MatDialog) {}

  ngOnInit() {
    // Setup logger
    if (environment.production) {
      Logger.enableProductionMode();
    }
    this.sub$ = this.auth.currentUser.subscribe({
      next: (u) => {
        // if (u.budgets && u.budgets.length === 0) {
        //   this.dialog.open(CreateBudgetDialogComponent, { disableClose: true });
        // }
      },
    });

    log.debug('init');
  }

  ngOnDestroy() {
    this.sub$?.unsubscribe();
  }
}
