<div class="fx-flex">
  <mat-sidenav-container class="fx-flex-fill">
    <mat-sidenav #sidenav mode="over" ngClass.gt-sm="has-border">
      <nav>
        <mat-list>
          <mat-divider></mat-divider>
          <!-- <span mat-button class="brand" routerLink="/home">Orçamento Mensal</span> -->

          <mat-divider></mat-divider>
          <a mat-list-item routerLink="/my-family" routerLinkActive="active" (click)="sidenav.close()">
            <mat-icon>group</mat-icon>
            <span>Orçamento</span>
          </a>
          <mat-divider></mat-divider>
          <a mat-list-item routerLink="/home" routerLinkActive="active" (click)="sidenav.close()">
            <mat-icon>bar_chart</mat-icon>
            <span>Inicio</span>
          </a>

          <a mat-list-item routerLink="/expenses" routerLinkActive="active" (click)="sidenav.close()">
            <mat-icon>attach_money</mat-icon>
            <span>Gastos</span>
          </a>
          <mat-divider></mat-divider>
          <a mat-list-item routerLink="/incomes" routerLinkActive="active" (click)="sidenav.close()">
            <mat-icon>account_balance</mat-icon>
            <span>Renda</span>
          </a>
          <mat-divider></mat-divider>
          <a mat-list-item routerLink="/buildings" routerLinkActive="active" (click)="sidenav.close()">
            <mat-icon>home</mat-icon>
            <span>Imóveis</span>
          </a>
          <mat-divider></mat-divider>
          <a mat-list-item routerLink="/inventory" routerLinkActive="active" (click)="sidenav.close()">
            <mat-icon>inventory_2</mat-icon>
            <span>Itens</span>
          </a>
          <!-- <a mat-list-item routerLink="/inicio" routerLinkActive="active" (click)="sidenav.close()">
            <span>Inicio</span>
          </a>
          <mat-divider></mat-divider>
          <a mat-list-item routerLink="/sobre" routerLinkActive="active" (click)="sidenav.close()">
            <span translate>Sobre</span>
          </a> -->
          <mat-divider></mat-divider>
        </mat-list>
      </nav>
    </mat-sidenav>
    <mat-sidenav-content style="flex: 1 1 auto; flex-direction: column" autosize="true">
      <app-header [sidenav]="sidenav"></app-header>
      <div class="fx-flex fx-layout-column content full-width">
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
