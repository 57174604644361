export class Page<T> {
  currentPage: number = 0;
  totalPages: number = 0;
  totalElements: number = 0;
  elements: T[] = [];

  pageSize: number = 0;
  public constructor(page?: Page<T>, mapper?: (value: any) => T) {
    if (!page) return;

    this.currentPage = page.currentPage;
    this.totalElements = page.totalElements;
    this.totalPages = page.totalPages;
    this.pageSize = page.pageSize;
    if (mapper) this.elements = page.elements.map(mapper);
  }
}
