import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrganizationChartModule } from 'primeng/organizationchart';
import { DragDropModule } from 'primeng/dragdrop';
import { TreeModule } from 'primeng/tree';
import { TreeSelectModule } from 'primeng/treeselect';
import { TreeTableModule } from 'primeng/treetable';
import { TableModule } from 'primeng/table';
import { ToolbarModule } from 'primeng/toolbar';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { DropdownModule } from 'primeng/dropdown';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { CalendarModule } from 'primeng/calendar';
import { PanelModule } from 'primeng/panel';
import { DividerModule } from 'primeng/divider';
import { InplaceModule } from 'primeng/inplace';
import { ProgressBarModule } from 'primeng/progressbar';
import { VirtualScrollerModule } from 'primeng/virtualscroller';
import { OrderListModule } from 'primeng/orderlist';
import { TagModule } from 'primeng/tag';
import { AvatarModule } from 'primeng/avatar';
import { InputTextModule } from 'primeng/inputtext';
import { AccordionModule } from 'primeng/accordion';
import { ContextMenuModule } from 'primeng/contextmenu';
import { ToastModule } from 'primeng/toast';
import { PickListModule } from 'primeng/picklist';
import { DataViewModule } from 'primeng/dataview';
import { FileUploadModule } from 'primeng/fileupload';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
@NgModule({
  declarations: [],
  imports: [CommonModule],
  exports: [
    OrganizationChartModule,
    DragDropModule,
    TreeModule,
    TreeTableModule,
    TreeSelectModule,
    ToolbarModule,
    TableModule,
    AutoCompleteModule,
    DropdownModule,
    BreadcrumbModule,
    CalendarModule,
    PanelModule,
    DividerModule,
    InplaceModule,
    ProgressBarModule,
    VirtualScrollerModule,
    OrderListModule,
    TagModule,
    AvatarModule,
    InputTextModule,
    AccordionModule,
    ContextMenuModule,
    ToastModule,
    PickListModule,
    DataViewModule,
    FileUploadModule,
    ConfirmDialogModule,
  ],
})
export class PrimeNgModule {}
