<div *ngIf="isLoading" class="loading-spinner">
  <mat-spinner></mat-spinner>
</div>
<div class="fx-flex fx-flex-fill fx-layout-column fx-align-center-center mat-typography">
  <mat-card>
    <mat-card-header class="password-reset-header">
      <mat-card-title class="full-width">
        <h1>Recuperar senha</h1>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div>
        <form
          [formGroup]="form"
          (ngSubmit)="onSubmit()"
          class="fx-flex fx-layout-column card-containers"
          style="max-width: 300px"
        >
          <app-password-input [formControl]="form.controls.password1" />
          <app-password-strength [password]="password" />
          <app-password-input [formControl]="form.controls.password2" />

          <mat-error>{{ form | formError }}</mat-error>
          <button mat-raised-button color="primary" type="submit" [disabled]="!form.valid">
            <span> Atualizar senha </span>
          </button>
          <a mat-button routerLink="/login">Login</a>
        </form>
      </div>
    </mat-card-content>
  </mat-card>
</div>
