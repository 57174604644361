import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './user-management/login/login.component';
import { RegisterComponent } from './user-management/register/register.component';
import { PasswordResetRequestComponent } from './password-management/password-reset-request/password-reset-request.component';
import { PasswordResetConfirmationComponent } from './password-management/password-reset-confirmation/password-reset-confirmation.component';
import { GuestGuard } from './guards/guest.guard';

const routes: Routes = [
  { path: 'login', component: LoginComponent, title: 'Entrar', canActivate: [GuestGuard] },
  { path: 'register', component: RegisterComponent, title: 'Cadastrar', canActivate: [GuestGuard] },
  {
    path: 'password-reset-request',
    component: PasswordResetRequestComponent,
    title: 'Recuperar senha',
    canActivate: [GuestGuard],
  },
  {
    path: 'password-reset-confirmation',
    component: PasswordResetConfirmationComponent,
    title: 'Alterar senha',
    canActivate: [GuestGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class AuthRoutingModule {}
