import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { DialogService } from '@app/@shared/service';
import { environment } from '@env/environment';
import { ApiResponseDialogComponent } from '@app/@shared/component/api-response-dialog/api-response-dialog.component';
import { ApiResponse } from '@app/@shared';
import { ApiErrorResponseDialogComponent } from '@app/@shared/component/api-error-response-dialog/api-error-response-dialog.component';
import { Router } from '@angular/router';
import { PasswordManagementService } from '@app/auth/services/password-management.service';
import { delay } from 'rxjs';

@Component({
  selector: 'app-password-reset-request',
  templateUrl: './password-reset-request.component.html',
  styleUrls: ['./password-reset-request.component.scss'],
})
export class PasswordResetRequestComponent {
  protected emailControl: FormControl<string> = new FormControl('', {
    nonNullable: true,
    validators: [Validators.email, Validators.required],
  });
  protected isLoading: boolean = false;
  protected isSuccess: boolean = false;
  private readonly apiUrl: string = environment.serverUrl;

  constructor(private httpService: PasswordManagementService, private router: Router, private dialog: DialogService) {}

  onSubmit(): void {
    if (this.emailControl.valid) {
      this.isLoading = true;
      this.httpService
        .requestPasswordReset(this.emailControl.value)
        .pipe(delay(3000)) //remove
        .subscribe({
          next: (response: ApiResponse) => {
            this.isLoading = false;
            this.isSuccess = true;
            // this.dialog
            //   .open(ApiResponseDialogComponent, { data: response })
            //   .afterClosed()
            // .pipe(delay(1000))
            // .subscribe(() => this.router.navigate(['login']));
          },
          error: (response: HttpErrorResponse) => {
            this.isLoading = false;
            this.dialog.open(ApiErrorResponseDialogComponent, { data: response['error'] });
          },
        });
    }
  }
}
