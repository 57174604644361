// import { Color } from '@angular-material-components/color-picker';
import { Component, Input } from '@angular/core';
import { Color } from '@app/@shared/model/color';

@Component({
  selector: 'app-color-tag',
  templateUrl: './color-tag.component.html',
  styleUrls: ['./color-tag.component.scss'],
})
export class ColorTagComponent {
  @Input() color?: string | Color;
  @Input() name?: string;
}
