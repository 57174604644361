import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-password-strength',
  templateUrl: './password-strength.component.html',
  styleUrls: ['./password-strength.component.scss'],
})
export class PasswordStrengthComponent {
  @Input() password!: string;
  protected color: string = 'primary';
  protected label: string = '';

  get strengthValue(): number {
    const lengthCriteria = this.password.length >= 4 && this.password.length < 100;
    const digitCriteria = /\d/.test(this.password);
    const lowerCriteria = /[a-z]/.test(this.password);
    const upperCriteria = /[A-Z]/.test(this.password);
    const specialCharCriteria = this.password.match('.*[!@#$%^&*()_+\\-=\\[\\]{};\':"\\\\|,.<>\\/?].*');

    const criteriaMet = [lengthCriteria, digitCriteria, lowerCriteria, upperCriteria, specialCharCriteria].filter(
      (b) => b
    ).length;

    switch (criteriaMet) {
      case 0:
      case 1:
        this.color = 'warn';
        this.label = 'Muito fraca';
        return 20;
      case 2:
        this.color = 'warn';
        this.label = 'Fraca';
        return 40;
      case 3:
        this.color = 'accent';
        this.label = 'Moderada';
        return 60;
      case 4:
        this.color = 'primary';
        this.label = 'Forte';
        return 80;
      case 5:
        this.color = 'primary';
        this.label = 'Muito forte';
        return 100;

      default:
        this.label = '';
        this.color = 'warn';
        return 0;
    }
  }
  get visible(): boolean {
    return !!this.password && this.password.length > 0;
  }
}
/**
 * if (password == null) {
    isValid = false;
    message = 'A senha não pode ser nula.';
    // valid length
  } else if (password.length < min) {
    isValid = false;
    message = `A senha deve ter pelo menos ${min} caracteres.`;
  } else if (password.length > max) {
    isValid = false;
    message = `A senha deve ter no máximo ${max} caracteres.`;
  }
  // at least one uppercase
  else if (!password.match('.*[A-Z].*')) {
    isValid = false;
    message = 'A senha deve conter pelo menos uma letra maiúscula.';
  }
  // at least one lowercase
  if (!password.match('.*[a-z].*')) {
    isValid = false;
    message = 'A senha deve conter pelo menos uma letra minúscula.';
    // at least one digit or special character
  } else if (!password.match('.*\\d.*') || !password.match('.*[!@#$%^&*()_+\\-=\\[\\]{};\':"\\\\|,.<>\\/?].*')) {
    isValid = false;
    message = 'A senha deve conter pelo menos um dígito ou caractere especial.';
  }
 */
