<div class="fx-flex fx-flex-fill fx-layout-column fx-align-center-center mat-typography">
  <div *ngIf="isLoading" class="loading-overlay">
    <mat-spinner class="loading-spinner"></mat-spinner>
  </div>

  <div class="is-success">
    <mat-card class="email-sent-card" *ngIf="isSuccess">
      <mat-card-header>
        <mat-icon class="email-icon" color="primary">email</mat-icon>
        <mat-card-title>E-mail enviado!</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <p>
          Um e-mail com o link de redefinição de senha foi enviado para o seu endereço. Verifique sua caixa de entrada!
        </p>
      </mat-card-content>
      <mat-card-actions>
        <button mat-raised-button color="primary" routerLink="/login">Voltar ao Login</button>
      </mat-card-actions>
    </mat-card>
  </div>

  <mat-card *ngIf="!isSuccess">
    <mat-card-header class="password-reset-header">
      <mat-card-title class="full-width">
        <h1>Recuperar senha</h1>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="fx-layout-column card-containers">
        <mat-form-field [hideRequiredMarker]="true" appearance="outline">
          <mat-label>Endereço de E-mail</mat-label>
          <input type="text" matInput [formControl]="emailControl" required />
          <mat-error>{{ emailControl | formError }}</mat-error>
        </mat-form-field>

        <button
          mat-raised-button
          color="primary"
          type="submit"
          [disabled]="!emailControl.valid || isLoading"
          (click)="onSubmit()"
        >
          <span *ngIf="isLoading">Enviando...</span>
          <span *ngIf="!isLoading"> Enviar Link de Recuperação </span>
        </button>
      </div>
      <a mat-button class="fx-layout-column" routerLink="/login">Voltar</a>
    </mat-card-content>
  </mat-card>
</div>
