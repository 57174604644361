import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { Shell } from '@app/shell/shell.service';
import { LoginComponent } from './auth/user-management/login/login.component';
import { RegisterComponent } from './auth/user-management/register/register.component';
import { SettingsRoutingModule } from './features/settings/settings-routing.module';
import { GuestGuard } from './auth/guards/guest.guard';

const routes: Routes = [
  // unprotected routes
  {
    path: '',
    pathMatch: 'full',
    canActivate: [GuestGuard],
    loadChildren: () => import('./landing-page/landing-page.module').then((m) => m.LandingPageModule),
  },
  {
    path: '',
    pathMatch: 'prefix',
    canActivate: [GuestGuard],
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  //protected routes
  Shell.childRoutes([
    {
      path: 'home',
      loadChildren: () => import('./features/home/home.module').then((m) => m.HomeModule),
    },
    {
      path: 'profile',
      children: SettingsRoutingModule.PROFILE_ROUTES,
      // loadChildren: () => import('./features/settings/settings.module').then((m) => m.SettingsModule),
    },
    {
      path: 'account',
      children: SettingsRoutingModule.ACCOUNT_ROUTES,
    },
    {
      path: 'settings',
      loadChildren: () => import('./features/settings/settings.module').then((m) => m.SettingsModule),
    },
    { path: 'buildings', loadChildren: () => import('./building/building.module').then((m) => m.BuildingModule) },
    {
      path: 'budget',
      loadChildren: () => import('./budget/budget.module').then((m) => m.BudgetModule),
    },
    { path: 'expenses', loadChildren: () => import('./expenses/expenses.module').then((m) => m.ExpensesModule) },
    { path: 'about', loadChildren: () => import('./about/about.module').then((m) => m.AboutModule) },

    // { path: 'history' },
    { path: 'incomes', loadChildren: () => import('./incomes/incomes.module').then((m) => m.IncomesModule) },
    // { path: 'inventory' },
  ]),

  // Fallback when no prior route is matched
  { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes /* { preloadingStrategy: PreloadAllModules } */)],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
