import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';

import { Logger } from '@shared';
import { CredentialsService } from '../services/credentials.service';

const log = new Logger('GuestGuard');

@Injectable({
  providedIn: 'root',
})
export class GuestGuard {
  constructor(private router: Router, private credentialsService: CredentialsService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    log.info(`I'm being called`);
    if (this.credentialsService.isAuthenticated()) {
      log.debug('Authenticated, redirecting to entrypoint...');
      this.router.navigate(['/home']);
      return false;
    }

    return true;
  }
}
