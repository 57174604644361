import { Injectable, Injector } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { AuthenticationService } from '@app/auth';

@Injectable()
export class ApiJwtInterceptor implements HttpInterceptor {
  constructor(private injector: Injector) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // if the request reffers to the api
    if (request.url.startsWith(environment.serverUrl)) {
      // if there's no authorization header
      if (!request.headers.has('Authorization')) {
        // set the Bearer token header
        // console.log('adding the authorization header...');
        const auth = this.injector.get(AuthenticationService);

        // get the auth token
        if (auth.accessToken == null || auth.accessToken == null) return next.handle(request);
        const token = auth.accessToken.slice();

        let params = request.params;
        console.log(auth.currentBudget);
        //TODO check this
        if (request.url.includes('/budget') && !request.url.endsWith('/create') && !!auth.currentBudget) {
          params = params.append('budgetId', auth.currentBudget.id);
        }

        const cloneReq = request.clone({
          headers: request.headers.set('Authorization', 'Bearer ' + token),
          params: params,
        });
        // log.debug(cloneReq);
        return next.handle(cloneReq);
      }
    }
    return next.handle(request);
  }
}
