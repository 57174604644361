import { Injectable, Injector } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, catchError } from 'rxjs';
import { Logger } from '@app/@shared';
import { AuthenticationService } from '../services/authentication.service';

const log = new Logger('ErrorHandlerInterceptor');
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private injector: Injector) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError((error) => this.errorHandler(error)));
  }

  // Customize the default error handler here if needed
  private errorHandler(response: HttpEvent<any>): Observable<HttpEvent<any>> {
    // Do something with the error
    const error: HttpErrorResponse = response as unknown as HttpErrorResponse;
    if (error.status === 401 || error.status === 403) {
      this.injector.get(AuthenticationService).logout();
    }
    log.error('Request error', response);

    throw response;
  }
}
