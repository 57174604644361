import { catchError, of, switchMap, take } from 'rxjs';
import { AuthenticationService } from './auth';
import { CredentialsService } from './auth/services/credentials.service';
import { Logger } from './@shared';
const log = new Logger('AppInitializer');
export function AppInitializer(authenticationService: AuthenticationService, credentials: CredentialsService) {
  return () => {
    log.info('Initializing app');
    return credentials.isAuthenticated$.asObservable().pipe(
      take(1),
      switchMap((isAuth) => {
        log.info(`Received authentication status ${isAuth}`);
        if (isAuth) {
          return authenticationService.userInfo();
        }

        return of(null);
      })
    );
  };
}
