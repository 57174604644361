import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { debounceTime, map, catchError, tap } from 'rxjs/operators';
import { AbstractControl, ValidationErrors, AsyncValidatorFn } from '@angular/forms';
import { environment } from '@env/environment';
const apiUrl: string = environment.serverUrl;
interface EmailAvailabilityResponse{
  isAvailable: boolean;
}
export function emailAsyncValidator(http: HttpClient): AsyncValidatorFn {
  return (control: AbstractControl): Observable<ValidationErrors | null> => {
    const email = control.value;
    if (!email) {
      return of(null);
    }

    return http.post<EmailAvailabilityResponse>(`${apiUrl}/api/users/check-email-availability`, { email: email }).pipe(
      debounceTime(500),
      map((response) => (response.isAvailable  ? null : { emailTaken: true })),
      catchError(() => of(null)) // Return null on error (no validation error)
    );
  };
}
