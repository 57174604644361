import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import { NgxScannerQrcodeModule, LOAD_WASM } from 'ngx-scanner-qrcode';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material.module';

import { RouteReusableStrategy, ApiPrefixInterceptor, ErrorHandlerInterceptor, SharedModule } from '@shared';
import { ShellModule } from './shell/shell.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { MAT_DATE_LOCALE } from '@angular/material/core';
// import { MAT_COLOR_FORMATS, MatColorFormats, NgxMatColorPickerModule } from '@angular-material-components/color-picker';
import { ConfirmationService } from 'primeng/api';
import { OAuthModule } from 'angular-oauth2-oidc';
// import { ApiJwtInterceptor } from './auth/api-jwt.interceptor';
import { AuthInterceptor } from './auth/interceptors/auth.interceptor';
import { ApiJwtInterceptor } from './auth/interceptors/api-jwt.interceptor';
import { AppInitializer } from './app.initializer';
import { AuthenticationService } from './auth';

LOAD_WASM().subscribe((res: any) => console.log('LOAD_WASM', res));
// export const CUSTOM_MAT_COLOR_FORMATS: MatColorFormats = {
//   display: {
//     colorInput: 'hex',
//   },
// };
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';

registerLocaleData(localePt);

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { CredentialsService } from './auth/services/credentials.service';
// import { LandingPageModule } from './landing-page/landing-page.module';
// import { IncomesModule } from './incomes/incomes.module';
// import { AuthModule } from '@app/auth/auth.module';
// import { HomeModule } from './home/home.module';
// import { BuildingModule } from './building/building.module';
// import { ExpensesModule } from './expenses/expenses.module';
// import { BudgetModule } from './budget/budget.module';

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    FormsModule,
    RouterModule,
    NgxScannerQrcodeModule,
    BrowserAnimationsModule,
    MaterialModule,
    SharedModule,
    ShellModule,

    // AuthModule,
    // HomeModule,
    // IncomesModule,
    // ExpensesModule,
    // BudgetModule,

    // BuildingModule,
    // OAuthModule.forRoot(),
    AppRoutingModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: AppInitializer,
      multi: true,
      deps: [AuthenticationService, CredentialsService],
    },
    ConfirmationService,
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    // { provide: MAT_COLOR_FORMATS, useValue: CUSTOM_MAT_COLOR_FORMATS },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiPrefixInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiJwtInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    // { provide: MAT_DATE_LOCALE, useValue: pt },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true,
    },
    {
      provide: RouteReuseStrategy,
      useClass: RouteReusableStrategy,
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}

platformBrowserDynamic().bootstrapModule(AppModule, {
  providers: [{ provide: LOCALE_ID, useValue: 'pt-BR' }],
});
