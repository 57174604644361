<!-- 
<div class="flex flex-row w-full h-full bg-wrapper" style="height: 100vh">
  

  <div class="p-8 flex flex-col justify-center  w-full bg-orange-50 ">
    <form
      (ngSubmit)="login()"
      [formGroup]="form"
      novalidate
      class="flex flex-col gap-4 mx-auto p-20 bg-white rounded-xl w-full"
    >
      <h2 class="text-center">Bem-vindo(a) de volta</h2>
      <mat-form-field [hideRequiredMarker]="true" appearance="outline" class="w-full">
        <mat-label>Usuário</mat-label>
        <input type="text" matInput formControlName="username" required />
        <mat-error>{{ form.controls.username | formError }}</mat-error>
      </mat-form-field>
      <app-password-input class="w-full" [formControl]="form.controls.password" />
      <a class="ml-auto text-blue-600 hover:text-blue-800" routerLink="/password-reset-request">Esqueceu sua senha?</a>

      <button mat-raised-button color="primary" type="submit" [disabled]="form.invalid || isLoading">
        <span>Entrar</span>
      </button>
      <hr />
      <p class="text-xl">
        Ainda não tem uma conta?
        <a class="ml-auto text-blue-600 hover:text-blue-800" routerLink="/register">Crie uma agora!</a>
      </p>
    </form>
  </div>
  <div class="h-full max-md:hidden lg:min-w-1/ lg:max-md:min-w-1/2">
    <img
      src="assets/trees.png"
      style="
        width: 100%;
        height: 100%;
        object-fit: cover;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50% 50%;
      "
      alt=""
    />
  </div> 
</div>
-->

<div class="bg-wrapper" style="min-height: 100vh; height: 100%">
  <div class="ml-auto flex flex-col justify-center h-full lg:w-1/2 xl:w-2/5 lg:bg-white">
    <div class="bg-white py-10 rounded-xl mx-2 sm:mx-auto max-w-xl lg:max-w-full">
      <div class="font-sans text-center">
        <h2 class=" ">Seja bem-vindo(a) de volta!</h2>
        <p>Pronto para continuar acompanhando suas finanças?</p>
      </div>
      <form (ngSubmit)="login()" [formGroup]="form" novalidate class="flex flex-col gap-4 mt-10 px-10 lg:px-20">
        <mat-form-field [hideRequiredMarker]="true" appearance="outline" class="w-full">
          <mat-label>Usuário</mat-label>
          <input type="text" matInput [formControl]="form.controls.username" required />
          <mat-error>{{ form.controls.username | formError }}</mat-error>
        </mat-form-field>
        <app-password-input class="w-full" [formControl]="form.controls.password" />

        <!-- <div class="w-full"> -->

        <a class="ml-auto text-blue-600 hover:text-blue-800" routerLink="/password-reset-request">
          Esqueceu sua senha?
        </a>

        <!-- <span class="">Não se preocupe, nós ajudamos você a recuperá-la.</span> -->
        <!-- </div> -->

        <button mat-raised-button color="primary" type="submit" [disabled]="form.invalid || isLoading">
          <span>Entrar</span>
        </button>
        <hr />
        <p class="text-xl text-center">
          Ainda não tem uma conta?
          <a class="ml-auto text-blue-600 hover:text-blue-800" routerLink="/register">Crie sua conta gratuita</a>
          e comece hoje mesmo
        </p>
      </form>
    </div>
  </div>
  <!-- <div class="h-full max-md:hidden lg:min-w-1/ lg:max-md:min-w-1/2">
    <img
      src="assets/trees.png"
      style="
        width: 100%;
        height: 100%;
        object-fit: cover;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50% 50%;
      "
      alt=""
    />
  </div> -->
</div>
