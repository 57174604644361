import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiErrorResponse } from '@shared/model';

@Component({
  selector: 'app-api-error-response-dialog',
  templateUrl: './api-error-response-dialog.component.html',
  styleUrls: ['./api-error-response-dialog.component.scss'],
})
export class ApiErrorResponseDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: ApiErrorResponse) {}
}
