<mat-form-field appearance="outline" subscriptSizing="dynamic" class="w-full">
  <mat-label>Senha</mat-label>
  <input
    [type]="inputType"
    matInput
    placeholder="Digite sua senha"
    [formControl]="formControl"
    aria-describedby="passwordHelp"
  />
  <mat-icon matSuffix (click)="toggleShowPassword()">
    {{ icon }}
  </mat-icon>
  <mat-error id="passwordHelp">{{ formControl | formError }}</mat-error>
</mat-form-field>
