<mat-toolbar class="mat-elevation-z6 navbar">
  <div class="fx-align-start-center fx-hide-gt-sm">
    <button class="menu-button" mat-icon-button (click)="sidenav.toggle()">
      <mat-icon>menu</mat-icon>
    </button>
    <span class="title">{{ title }}</span>
  </div>
  <div class="fx-hide-lt-md">
    <span mat-button class="brand" routerLink="/home">Meus Gastos</span>

    <button mat-button routerLink="/budget" routerLinkActive="active">
      <mat-icon>group</mat-icon>
      <span>Orçamento</span>
    </button>
    <button mat-button routerLink="/home" routerLinkActive="active">
      <mat-icon>bar_chart</mat-icon>
      <span>Dashboard</span>
    </button>
    <button mat-button routerLink="/expenses" routerLinkActive="active">
      <mat-icon>attach_money</mat-icon>
      <span>Gastos</span>
    </button>
    <button mat-button routerLink="/incomes" routerLinkActive="active">
      <mat-icon>account_balance</mat-icon>
      <span>Renda</span>
    </button>
    <button mat-button routerLink="/buildings" routerLinkActive="active">
      <mat-icon>home</mat-icon>
      <span>Imóveis</span>
    </button>
    <button mat-button routerLink="/inventory" routerLinkActive="active">
      <mat-icon>inventory_2</mat-icon>
      <span>Itens</span>
    </button>
  </div>
  <!-- class="fx-flex" -->
  <span style="flex: 1 1 0%; box-sizing: border-box"></span>
  <button mat-icon-button [matMenuTriggerFor]="userMenu">
    <mat-icon>person</mat-icon>
  </button>
  <mat-menu #userMenu="matMenu">
    <mat-list>
      <mat-list-item>
        <span>Logado como </span>&nbsp;<b>{{ (username | async)?.givenName }}</b>
      </mat-list-item>
      <mat-divider></mat-divider>
      <mat-list-item routerLink="/profile">
        <span>Perfil</span>
      </mat-list-item>
      <mat-list-item routerLink="/account">
        <span>Conta</span>
      </mat-list-item>
      <mat-list-item routerLink="/settings">
        <span>Configurações</span>
      </mat-list-item>
      <mat-list-item routerLink="/help">
        <span>Ajuda e suporte</span>
      </mat-list-item>
    </mat-list>
    <button mat-menu-item (click)="logout()">Logout</button>
  </mat-menu>
</mat-toolbar>
