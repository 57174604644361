<h1 mat-dialog-title>{{ title }}</h1>
<div mat-dialog-content>
  {{ message }}
</div>
<div mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="false" type="button">{{ cancel }}</button>
  <button
    mat-raised-button
    [mat-dialog-close]="true"
    type="button"
    [disabled]="!displayConfirmButton"
    [color]="confirmColor"
  >
    {{ confirm }}
  </button>
</div>
