import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EditProfileComponent } from './profile/edit-profile/edit-profile.component';
import { ProfileOverviewComponent } from './profile/profile-overview/profile-overview.component';
import { AccountOverviewComponent } from './account/components/account-overview/account-overview.component';
import { PasswordChangeComponent } from './account/components/password-change/password-change.component';
import { EmailChangeComponent } from './account/components/email-change/email-change.component';
import { PrivacyComponent } from './account/components/privacy/privacy.component';
import { NotificationComponent } from './account/components/notification/notification.component';
import { ThemeComponent } from './account/components/theme/theme.component';
import { SettingsComponent } from './settings.component';

const routes: Routes = [
  {
    path: '',
    component: SettingsComponent, // The general settings overview page
    // children: [
  },
  // {
  //   path: 'profile',
  //   children: [
  //     {
  //       path: 'edit',
  //       component: EditProfileComponent,
  //       title: 'Editar Perfil',
  //     },
  //     {
  //       path: '',
  //       pathMatch: 'full',
  //       component: ProfileOverviewComponent,
  //     },
  //   ],
  // },
  // {
  //   path: 'account',
  //   children: [
  //     {
  //       path: '',
  //       pathMatch: 'full',
  //       component: AccountOverviewComponent,
  //     },
  //     {
  //       path: 'password-change',
  //       component: PasswordChangeComponent,
  //     },
  //     {
  //       path: 'email-change',
  //       component: EmailChangeComponent,
  //     },
  //     {
  //       path: 'privacy',
  //       component: PrivacyComponent,
  //     },
  //     {
  //       path: 'notifications',
  //       component: NotificationComponent,
  //     },
  //     {
  //       path: 'theme',
  //       component: ThemeComponent,
  //     },
  //   ],
  // },
  // //   ],
  // },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SettingsRoutingModule {
  static PROFILE_ROUTES: Routes = [
    {
      path: 'edit',
      component: EditProfileComponent,
      title: 'Editar Perfil',
    },
    {
      path: '',
      pathMatch: 'full',
      component: ProfileOverviewComponent,
    },
  ];
  static ACCOUNT_ROUTES: Routes = [
    {
      path: '',
      pathMatch: 'full',
      component: AccountOverviewComponent,
    },
    {
      path: 'password-change',
      component: PasswordChangeComponent,
    },
    {
      path: 'email-change',
      component: EmailChangeComponent,
    },
    {
      path: 'privacy',
      component: PrivacyComponent,
    },
    {
      path: 'notifications',
      component: NotificationComponent,
    },
    {
      path: 'theme',
      component: ThemeComponent,
    },
  ];
}
