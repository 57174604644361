import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiResponse } from '@app/@shared/model';

@Component({
  selector: 'app-api-response-dialog',
  templateUrl: './api-response-dialog.component.html',
  styleUrls: ['./api-response-dialog.component.scss'],
})
export class ApiResponseDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: ApiResponse) {}
}
