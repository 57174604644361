import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmationData } from '@app/@shared/service';
export interface ConfirmationDialogData extends ConfirmationData {}
@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent {
  displayConfirmButton: boolean = false;
  constructor(@Inject(MAT_DIALOG_DATA) private data: ConfirmationDialogData) {
    // if there's no delay, display the confirm button
    if (data.confirmButtonDelay === undefined) {
      this.displayConfirmButton = true;
    } else {
      // if there's delay, then sets it as timeout and display the confirm button after its time
      setTimeout(() => (this.displayConfirmButton = true), this.data.confirmButtonDelay);
    }
  }

  get title(): string {
    return this.data.title;
  }

  get message(): string {
    return this.data.message;
  }
  get cancel(): string {
    return this.data.cancelLabel || 'Cancelar';
  }
  get confirm(): string {
    return this.data.confirmLabel || 'Confirmar';
  }
  get confirmColor(): string {
    return this.data.confirmColor || ' ';
  }
}
