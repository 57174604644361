import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiResponse } from '@app/@shared';
import { ApiErrorResponseDialogComponent } from '@app/@shared/component/api-error-response-dialog/api-error-response-dialog.component';
import { ApiResponseDialogComponent } from '@app/@shared/component/api-response-dialog/api-response-dialog.component';
import { DialogService } from '@app/@shared/service';

import { passwordValidator } from '@app/auth/password-management/validators/password-validator';
import { environment } from '@env/environment';
export interface ChangePasswordForm {
  password1: FormControl<string>;
  password2: FormControl<string>;
}
@Component({
  selector: 'app-password-reset-confirmation',
  templateUrl: './password-reset-confirmation.component.html',
  styleUrls: ['./password-reset-confirmation.component.scss'],
})
export class PasswordResetConfirmationComponent implements OnInit {
  showPassword1: boolean = false;
  showPassword2: boolean = false;
  private readonly apiUrl: string = environment.serverUrl;
  token!: string;
  isLoading: boolean = false;
  form: FormGroup<ChangePasswordForm>;

  constructor(
    private aRoute: ActivatedRoute,
    private http: HttpClient,
    private dialog: DialogService,
    private fb: FormBuilder,
    private router: Router
  ) {
    this.form = fb.group<ChangePasswordForm>(
      {
        password1: this.fb.nonNullable.control<string>('', [Validators.required, passwordValidator]),
        password2: this.fb.nonNullable.control<string>('', [Validators.required]),
      },
      { validators: [this.passwordMatchValidator] }
    );
  }

  passwordMatchValidator(ctrl: AbstractControl): ValidationErrors | null {
    const group: FormGroup<ChangePasswordForm> = ctrl as unknown as FormGroup<ChangePasswordForm>;
    const password1: FormControl<string> = group.controls.password1;
    const password2: FormControl<string> = group.controls.password2;
    if (password1.value === password2.value) return null;
    if (!password1.dirty || !password2.dirty) return null;
    return { passwordsMatch: 'As senhas não coincidem' };
  }

  ngOnInit(): void {
    this.token = this.aRoute.snapshot.queryParams['token'];
    if (!!!this.token) {
      // this.dialog.open()
      console.log('token');
    }
  }

  onSubmit(): void {
    this.http
      .post<ApiResponse>(`${this.apiUrl}/api/auth/reset-password/confirm`, {
        newPassword: this.form.value.password1,
        resetToken: this.token,
      })
      .subscribe({
        next: (response: ApiResponse) => {
          this.dialog
            .open(ApiResponseDialogComponent, { data: response })
            .afterClosed()
            .subscribe(() => this.router.navigate(['login']));
        },
        error: (response: HttpErrorResponse) => {
          this.dialog.open(ApiErrorResponseDialogComponent, { data: response['error'] });
        },
      });
  }
  get password(): string {
    return this.form.getRawValue().password1;
  }
}
