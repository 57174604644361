import { CurrencyPipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Logger } from '@app/@shared/logger.service';
const log = new Logger('CurrencyFormInput');
@Component({
  selector: 'app-currency-form-input',
  templateUrl: './currency-form-input.component.html',
  styleUrls: ['./currency-form-input.component.scss'],
  providers: [CurrencyPipe],
})
export class CurrencyFormInputComponent implements OnInit {
  @Input() control?: FormControl<number>;
  maskedControl: FormControl<string> = new FormControl<string>('', { nonNullable: true });

  @Input() label?: string;
  @Input() appearance: 'fill' | 'outline' = 'outline';
  @Input() subscriptSizing: 'fixed' | 'dynamic' = 'fixed';

  constructor(private currency: CurrencyPipe) {}

  ngOnInit(): void {
    this.maskedControl.patchValue(this.currency.transform(this.control!.value.toString(), 'BRL')!);
    this.maskedControl.valueChanges.subscribe({
      next: (value) => {
        // reads the value changes to the inner input and casts to a number
        const plainNumber = this.getPlainNumber(value);
        if (this.control && this.control.value !== plainNumber) {
          this.control.markAsDirty();
        }
        this.control!.patchValue(plainNumber);
      },
    });
  }

  private getPlainNumber(value: string): number {
    if (!value) return 0;

    var plainNumber: number;

    var decimalSeparatorIndex = value.lastIndexOf(',');
    if (decimalSeparatorIndex > 0) {
      // if input has decimal part
      var wholeNumberPart = value.substring(0, decimalSeparatorIndex);
      var decimalPart = value.substring(decimalSeparatorIndex + 1);
      plainNumber = parseFloat(wholeNumberPart.replace(/[^\d]/g, '') + '.' + decimalPart);
    } else {
      // input does not have decimal part
      plainNumber = parseFloat(value.replace(/[^\d]/g, ''));
    }
    return plainNumber;
  }
}
