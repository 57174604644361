import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl, ValidationErrors } from '@angular/forms';
import { finalize } from 'rxjs/operators';

import { environment } from '@env/environment';
import { Logger, UntilDestroy, untilDestroyed } from '@shared';
import { AuthenticationService } from '../../services/authentication.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';

const log = new Logger('Login');
interface LoginResponse {
  username: boolean;
  password: boolean;
  status: boolean;
}

interface LoginForm {
  username: FormControl<string>;
  password: FormControl<string>;
}

@UntilDestroy()
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  version: string | null = environment.version;
  form!: FormGroup<LoginForm>;
  isLoading = false;
  showPassword: boolean = false;
  formSub!: Subscription;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private authenticationService: AuthenticationService
  ) {
    this.form = this.fb.group<LoginForm>({
      username: this.fb.nonNullable.control<string>('', [Validators.required]),
      password: this.fb.nonNullable.control<string>('', [Validators.required]),
    });
  }

  ngOnInit() {
    this.formSub = this.form.valueChanges.subscribe(() => {
      console.log('amigoesotuaqui');
      console.log(this.form);
      if (this.usernameControl.hasError('badCredentials') || this.passwordControl.hasError('badCredentials')) {
        this.usernameControl.setErrors(null);
        this.passwordControl.setErrors(null);
        this.form.updateValueAndValidity({ emitEvent: false });
      }
    });

    console.log(this.form);
  }

  login() {
    this.isLoading = true;
    const login$ = this.authenticationService.login(this.form.getRawValue());
    login$
      .pipe(
        finalize(() => {
          this.form.markAsPristine();
          this.isLoading = false;
        }),
        untilDestroyed(this)
      )
      .subscribe({
        next: () => {
          log.debug(`${this.form.getRawValue().username} successfully logged in`);
          this.router.navigate([this.route.snapshot.queryParams['redirect'] || '/home'], { replaceUrl: true });
        },
        error: (error: HttpErrorResponse) => {
          log.error(error);
          const status = error.status;
          const response: LoginResponse = error.error;
          console.log(response);
          if (!response.username || !response.password) {
            this.usernameControl.setErrors({
              badCredentials: true,
            });
          }
          if (!response.status) {
            this.form.setErrors({
              status: true,
            });
          }
          log.debug(`Login error: ${error}`);
        },
      });
  }
  get usernameControl(): FormControl<string> {
    return this.form.controls.username;
  }
  get passwordControl(): FormControl<string> {
    return this.form.controls.password;
  }
}
