import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Budget } from '@app/budget';
import { Observable } from 'rxjs';
import { AuthenticationService } from '@app/auth';
import { Logger } from '@app/@shared';

const log = new Logger('BudgetService');
@Injectable({
  providedIn: 'root',
})
export class BudgetService {
  constructor(private http: HttpClient) {}

  public createBudget(dto: {}): Observable<Budget> {
    log.debug('POST /api/budgets/create');
    return this.http.post<Budget>(`/api/budgets/create`, dto);
  }
  public getBudgets(): Observable<Budget[]> {
    log.debug('GET /api/budgets');
    return this.http.get<Budget[]>(`/api/budgets`);
  }
  public getBudget(): Observable<Budget> {
    log.debug('POST /api/budgets/create');
    return this.http.get<Budget>(`/api/budgets/monthly/current`);
  }
  public updateBudget(dto: {}): Observable<Budget> {
    return this.http.put<Budget>(`/api/budgets`, dto);
  }
  public deleteBudget(): Observable<HttpResponse<any>> {
    return this.http.delete<HttpResponse<any>>(`/api/budgets`, { observe: 'response' });
  }
}
