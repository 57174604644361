import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '@app/auth/services/authentication.service';
import { Router } from '@angular/router';
import { passwordValidator } from '@app/auth/password-management/validators/password-validator';
import { emailAsyncValidator } from '@app/auth/password-management/validators/email-async-validator';
import { HttpClient } from '@angular/common/http';
export interface CreateUserDto {
  givenName: string;
  familyName: string;
  username: string;
  email: string;
  password: string;
}
interface CreateUserForm {
  givenName: FormControl<string>;
  familyName: FormControl<string>;
  picture?: FormControl<string>;
  username: FormControl<string>;
  email: FormControl<string>;
  password: FormControl<string>;
}
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent {
  form: FormGroup<CreateUserForm>;
  isLoading: boolean = false;
  constructor(
    private fb: FormBuilder,
    private service: AuthenticationService,
    private http: HttpClient,
    private router: Router
  ) {
    this.form = this.fb.group<CreateUserForm>({
      givenName: this.fb.nonNullable.control<string>('', [Validators.required]),
      familyName: this.fb.nonNullable.control<string>('', [Validators.required]),
      username: this.fb.nonNullable.control<string>('', [Validators.required]),
      email: this.fb.nonNullable.control<string>('', [Validators.required], [emailAsyncValidator(this.http)]),
      password: this.fb.nonNullable.control<string>('', [Validators.required, passwordValidator]),
    });
  }
  onSubmit(): void {
    if (this.form.valid) {
      const dto = this.form.getRawValue();
      this.isLoading = true;
      this.service.register(dto).subscribe({
        next: (value) => {
          console.log(value);
          // this.router.navigate(['/login']);
        },
      });
    }
  }
}
