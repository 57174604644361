import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from '@shared';
import { MaterialModule } from '@app/material.module';
import { AuthRoutingModule } from './auth-routing.module';
import { LoginComponent } from './user-management/login/login.component';
import { RegisterComponent } from './user-management/register/register.component';
import { PasswordResetRequestComponent } from './password-management/password-reset-request/password-reset-request.component';
import { PasswordResetConfirmationComponent } from './password-management/password-reset-confirmation/password-reset-confirmation.component';
import { PasswordInputComponent } from './password-management/password-input/password-input.component';
import { PasswordStrengthComponent } from './password-management/password-strength/password-strength.component';

@NgModule({
  declarations: [
    LoginComponent,
    RegisterComponent,
    PasswordResetRequestComponent,
    PasswordResetConfirmationComponent,
    PasswordInputComponent,
    PasswordStrengthComponent,
  ],
  imports: [CommonModule, AuthRoutingModule, SharedModule, ReactiveFormsModule, MaterialModule],
})
export class AuthModule {}
