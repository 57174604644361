import { Budget } from '@app/budget';
import { Person } from './person';

export class User extends Person {
  username!: string;
  email!: string;

  constructor(u: User) {
    console.log(u);
    super(u);
    this.username = u.username;
    this.email = u.email;
  }
}
