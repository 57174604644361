import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { UpdateBuildingDto } from '@app/building/update-building-dialog/update-building-dialog.component';
import { AuthenticationService } from '@app/auth';
import { CreateBuildingDto } from './create-building-dialog/create-building-dialog.component';
import { Building } from '@app/building';

@Injectable({
  providedIn: 'root',
})
export class BuildingService {
  private budgetId: number = 1;

  constructor(private http: HttpClient, private auth: AuthenticationService) {}

  public createBuilding(dto: CreateBuildingDto): Observable<Building> {
    return this.http.post<Building>(`/budget/buildings`, dto);
  }
  public getBuildings(): Observable<Building[]> {
    return this.http.get<Building[]>(`/budget/buildings`);
  }
  public updateBuilding(buildingId: number, dto: UpdateBuildingDto): Observable<Building> {
    return this.http.put<Building>(`/budget/buildings/${buildingId}`, dto);
  }
  public deleteBuilding(buildingId: number): Observable<HttpResponse<any>> {
    return this.http.delete<HttpResponse<any>>(`/budget/buildings/${buildingId}`, {
      observe: 'response',
    });
  }
}
