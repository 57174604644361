import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-password-input',
  templateUrl: './password-input.component.html',
  styleUrls: ['./password-input.component.scss'],
})
export class PasswordInputComponent {
  @Input() formControl!: FormControl;
  showPassword: boolean = false;

  protected toggleShowPassword(): void {
    this.showPassword = !this.showPassword;
  }

  get inputType(): string {
    return this.showPassword ? 'text' : 'password';
  }
  get icon(): string {
    return this.showPassword ? 'visibility_off' : 'visibility';
  }
}
