import { ComponentPortal, ComponentType } from '@angular/cdk/portal';
import { Injectable, Injector, Provider, ViewContainerRef } from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';

@Injectable({
  providedIn: 'root',
})
export class OverlayService {
  currentRef?: OverlayRef;
  constructor(private overlay: Overlay) {}

  public openRightSidePanelWithComponent<T>(
    component: ComponentType<T>,
    provider: Provider,
    viewContainerRef: ViewContainerRef,
    config?: OverlayConfig
  ): OverlayRef {
    const overlayConfig = new OverlayConfig({
      ...config,
      positionStrategy: this.overlay.position().global().top().right(),
    });
    this.currentRef = this.overlay.create(overlayConfig);
    this.currentRef.updatePosition();

    this.currentRef.attach(
      new ComponentPortal(
        component,
        viewContainerRef,
        Injector.create({
          providers: [provider],
        })
      )
    );
    this.currentRef.backdropClick().subscribe(() => this.currentRef?.dispose());
    this.currentRef.detachments();

    // this.income = i;
    // this.selected = true;
    // this.router.navigate([i.id], { relativeTo: this.aRoute });
    return this.currentRef;
  }

  public close(): void {
    this.currentRef?.detach();
    this.currentRef?.dispose();
  }
}
