import { Budget } from '@app/budget';

export class Person {
  id!: number;
  givenName!: string;
  familyName: string | null = null;
  budgets: Budget[] = [];
  private _picture: string = '';
  public constructor(p: Person) {
    this.id = p.id;
    this.givenName = p.givenName;
    this.familyName = p.familyName;
    this._picture = p.picture;
    if (!!p.budgets) this.budgets = p.budgets.map((c) => new Budget(c));
  }

  get fullName(): string {
    return this.givenName + ' ' + this.familyName;
  }

  /**
   * Gets the picture of the user
   */
  public get picture(): string {
    // if there's no pic or the pic is empty, returns the default one
    if (!this._picture || this._picture === '') {
      return 'assets/media/avatar.svg';
    }
    // if it's an url, returns the url value
    if (this._picture.startsWith('http') || this._picture.startsWith('data:')) {
      return this._picture;
    }
    if (this._picture.startsWith('assets/media/')) {
      return this._picture;
    }
    return 'assets/media/' + this._picture;
  }
  public set picture(p: string) {
    this._picture = p;
  }
}
