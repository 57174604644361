import { HttpParams } from '@angular/common/http';
import { TableLazyLoadEvent } from 'primeng/table';
// import { LazyLoadEvent } from 'primeng/api';
export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc',
}
export class Sort {
  readonly property: string;
  readonly sort: SortDirection;

  public constructor(property: string, sort: SortDirection) {
    this.property = property;
    this.sort = sort;
  }

  public toString(): string {
    return this.property + ',' + this.sort;
  }
}

export class Pageable {
  private _page: number;
  private _size: number;
  private _sort!: Sort[];

  public constructor(page: number = 0, size: number = 10, sort: Sort[] = []) {
    this._page = page;
    this._size = size;
    this._sort = sort;
  }
  public static lazyLoad(ev: TableLazyLoadEvent, sort?: Sort[]): Pageable {
    const first = ev.first || undefined;
    const size = ev.rows || undefined;
    if (first !== undefined && size !== undefined) {
      if (first === 0) {
        return new Pageable(0, size, sort);
      }
      const page = first / size;
      return new Pageable(page, size, sort);
    } else {
      console.error('unable to read the first/rows values from LazyLoadEvent: ', ev);
    }
    return new Pageable(0, 10);
  }
  // public static lazyLoad(ev: LazyLoadEvent, sort?: Sort[]): Pageable {
  //   const first = ev.first;
  //   const size = ev.rows;
  //   if (first !== undefined && size !== undefined) {
  //     if (first === 0) {
  //       return new Pageable(0, size, sort);
  //     }
  //     const page = first / size;
  //     return new Pageable(page, size, sort);
  //   } else {
  //     console.error('unable to read the first/rows values from LazyLoadEvent: ', ev);
  //   }
  //   return new Pageable();
  // }
  public get page(): number {
    return this._page;
  }
  public get size(): number {
    return this._size;
  }

  public nextPage(): void {
    this._page = this._page + 1;
  }

  public set size(size: number) {
    if (size > 0) {
      this._size = size;
    }
  }
  public set page(page: number) {
    if (page >= 0) {
      this._page = page;
    }
  }

  public get sort(): string[] {
    return this._sort.map((s) => s.toString());
  }

  public addSort(property: string, sort: SortDirection): void {
    console.log('removing...', property, sort);

    // removes the sort from the pageable
    this.removeSort(property);
    // if theres a sort order, creates a new one and push it to the pageable
    this._sort.push(new Sort(property, sort));
  }

  public removeSort(property: string): void {
    // find the index of the property
    const i = this._sort.findIndex((s) => s.property === property);
    // if the propert exists, remove from the list
    if (i >= 0) {
      this._sort.splice(i, 1);
    }
    console.log(this._sort);
  }

  toHttpParam(): HttpParams {
    var params = new HttpParams().set('page', this._page).set('size', this._size);
    if (this._sort.length > 0) {
      return params.appendAll({ sort: this.sort });
    }
    return params;
  }
}
