import { AbstractControl, ValidationErrors, Validator } from '@angular/forms';

const min: number = 4;
const max: number = 100;
export function passwordValidator(control: AbstractControl): ValidationErrors | null {
  const password: string = control.value;
  let message: string = '';
  let isValid: boolean = true;
  // validate not null
  if (password == null) {
    isValid = false;
    message = 'A senha não pode ser nula.';
    // valid length
  } else if (password.length < min) {
    isValid = false;
    message = `A senha deve ter pelo menos ${min} caracteres.`;
  } else if (password.length > max) {
    isValid = false;
    message = `A senha deve ter no máximo ${max} caracteres.`;
  }
  // at least one uppercase
  else if (!password.match('.*[A-Z].*')) {
    isValid = false;
    message = 'A senha deve conter pelo menos uma letra maiúscula.';
  }
  // at least one lowercase
  if (!password.match('.*[a-z].*')) {
    isValid = false;
    message = 'A senha deve conter pelo menos uma letra minúscula.';
    // at least one digit or special character
  } else if (!password.match('.*\\d.*') || !password.match('.*[!@#$%^&*()_+\\-=\\[\\]{};\':"\\\\|,.<>\\/?].*')) {
    isValid = false;
    message = 'A senha deve conter pelo menos um dígito ou caractere especial.';
  }
  if (!isValid) {
    return { invalidPassword: message };
  }
  return null;
}
