import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ConfirmationDialogComponent,
  ConfirmationDialogData,
} from '../component/confirmation-dialog/confirmation-dialog.component';
import { Observable, map } from 'rxjs';
export interface ConfirmationData {
  title: string;
  message: string;
  confirmLabel?: string;
  confirmColor?: string;
  cancelLabel?: string;
  confirmButtonDelay?: number;
}
@Injectable({
  providedIn: 'root',
})
export class ConfirmationService {
  constructor(private dialog: MatDialog) {}

  public confirm(data: ConfirmationData): Observable<boolean> {
    return this.dialog
      .open<ConfirmationDialogComponent, ConfirmationDialogData, boolean>(ConfirmationDialogComponent, { data })
      .afterClosed()
      .pipe(map((r) => !!r));
  }

  public discardChanges(): Observable<boolean> {
    return this.dialog
      .open<ConfirmationDialogComponent, ConfirmationDialogData, boolean>(ConfirmationDialogComponent, {
        data: {
          title: 'Fechar?',
          message: 'Você deseja fechar sem salvar as mudanças?',
          confirmLabel: 'Fechar',
          cancelLabel: 'Continuar',
        },
      })
      .afterClosed()
      .pipe(map((r) => !!r));
  }
}
