import { Pipe, PipeTransform } from '@angular/core';
import { FormControl, FormGroup, ValidationErrors } from '@angular/forms';

@Pipe({
  name: 'formError',
  pure: false,
})
export class FormErrorPipe implements PipeTransform {
  /**
   * Transforms an FormError to a translated string message
   * @param control the form control containing the errors
   * @param args additional parameters (not used)
   * @returns a string containing the translated error message
   */
  transform(control?: FormControl<any> | FormGroup<any>, ...args: unknown[]): string {
    // throw error if the control is undefined
    if (control === undefined) throw new Error("The control property can't be undefined");
    const errors: ValidationErrors | null = control.errors;
    // return if there's no errors
    if (errors === null) return '';
    const errorKey: string = Object.keys(errors)[0];
    switch (errorKey) {
      case 'required':
        return this.required();
      case 'email':
        return this.email();
      case 'min':
        return this.min(errors[errorKey]);
      case 'max':
        return this.max(errors[errorKey]);
      case 'minlength':
        return this.minlength(errors[errorKey]);
      case 'maxlength':
        return this.maxlength(errors[errorKey]);
      case 'pattern':
        return this.pattern(errors[errorKey]);
      case 'compose':
        return this.compose(errors[errorKey]);
      case 'composeAsync':
        return this.composeAsync(errors[errorKey]);
      case 'slugExists':
        return this.receiptExists(control.value);
      case 'badCredentials':
        return 'Usuário ou senha inválidos';
      case 'usernameNotFound':
        return this.usernameNotFound();
      case 'badPassword':
        return this.badPassword();
      case 'emailTaken':
        return `O endereço de e-mail ${control.value} já está sendo utilizado.`;
      case 'passwordsMatch':
        return 'As senhas não coincidem';
      case 'invalidPassword':
        return errors['invalidPassword'];
      case 'status':
        return `O acesso a esta conta está bloqueado`;
      default:
        console.log(errors);
        return this.default(control.value);
    }
  }

  private min(error: { min: number; actual: number }): string {
    return `Valor deve ser maior que: ${error.min}, valor atual é: ${error.actual}`;
  }
  private max(error: { min: number; actual: number }): string {
    return `"Valor deve ser menor que: ${error.min}, valor atual é: ${error.actual}`;
  }
  private required(): string {
    return `Este campo é obrigatório`;
  }
  private email(): string {
    return `Email inválido`;
  }
  private minlength(error: { requiredLength: number; actualLength: number }): string {
    return `Deve conter ao menos ${error.requiredLength} caracteres, o valor atual é: ${error.actualLength}`;
  }

  private maxlength(error: { requiredLength: number; actualLength: number }): string {
    return `Não deve ultrapassar ${error.requiredLength} caracteres, o valor atual é: ${error.actualLength}`;
  }
  private pattern(error: { requiredPattern: '^[a-zA-Z ]*$'; actualValue: '1' }): string {
    return '';
  }
  private compose(error: any): string {
    return '';
  }
  private composeAsync(error: any): string {
    return '';
  }
  private receiptExists(receipt: string): string {
    return `A nota  \"${receipt}\" já está cadastrada`;
  }
  private usernameNotFound(): string {
    return `Usuário não encontrado`;
  }
  private badPassword(): string {
    return `Senha inválida`;
  }
  private default(value: any): string {
    return `Valor inválido: ${value}`;
  }
}
