import { ApiResponse } from './api-response';
import { ApiSubError } from './api-sub-error';

export class ApiErrorResponse extends ApiResponse {
  private _timestamp: Date | string = '';

  // the error title being displayed to the user

  public error!: string;

  //User-friendly message about the error
  public override message: string | null = null;
  // the request relative path
  public path!: string;
  // error message with debug purposes

  public debugMessage!: string;
  private httpStatus!: number;

  private subErrors: ApiSubError[] | null = null;

  set timestamp(value: Date | string) {
    this._timestamp = value;
  }
  public get timestamp(): Date {
    return new Date(this._timestamp);
  }
}
